import { Route, Routes } from "react-router";
import {
  Charities,
  CreateFundraiser,
  Fundraisers,
  Home,
  Users,
  Settings,
  FundraiserDetail,
  CharityDetail,
  Donate,
  EventDetails,
  TermsAndConditions,
  FindEvent,
  SearchEvent,
  NotFound,
  StartEvent,
  InMemoryOf,
  InCelebration,
  Transfers,
  PrivacyPolicy,
  Favors,
  FavorItems,
} from "pages";
import { ProfileInfo } from "utils";
import { PaymentSuccess } from "../components";

export const Routers = () => {
  const { token, ifAdmin } = ProfileInfo();

  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/start-event"} element={<StartEvent />} />
      <Route path={"/in-memory-of"} element={<InMemoryOf />} />
      <Route path={"/in-celebration"} element={<InCelebration />} />

      {token && <Route path={"/events"} element={<Fundraisers />} />}
      {token && <Route path={"/events/:id"} element={<FundraiserDetail />} />}
      {token && (
        <Route path={"/create-fundraiser"} element={<CreateFundraiser />} />
      )}
      {ifAdmin && token && <Route path={"/users"} element={<Users />} />}
      {ifAdmin && token && (
        <Route path={"/charities"} element={<Charities />} />
      )}
      {ifAdmin && token && (
        <Route path={"/charity/:id"} element={<CharityDetail />} />
      )}
      {token && <Route path={"/settings"} element={<Settings />} />}

      {token && <Route path={"/transfers"} element={<Transfers />} />}

      {ifAdmin && token && <Route path={"/favors"} element={<Favors />} />}
      {ifAdmin && token && (
        <Route path={"/favor-items"} element={<FavorItems />} />
      )}

      <Route path={"/find-event"} element={<FindEvent />} />
      <Route path={"/event/:id"} element={<EventDetails />} />
      <Route path={"/search-event"} element={<SearchEvent />} />
      <Route path={"/donate/:id"} element={<Donate />} />
      <Route path={"/terms"} element={<TermsAndConditions />} />
      <Route path={"/privacy"} element={<PrivacyPolicy />} />
      <Route path={"/payment-success"} element={<PaymentSuccess />} />

      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};
