import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ButtonMain, PageLoader, SearchInput } from "components";
import { Svg } from "assets";
import { FindLoad, useModal } from "utils";
import { favorsActions } from "store";
import { useDispatch, useSelector } from "react-redux";
import { FavorSmallCard, SortBy, sortItems } from "fragments";

export const ItemsModal = ({ handleItemInList }) => {
  const [showActiveItem, setShowActiveItem] = useState(null);

  const { favorsForFilter } = useSelector((state) => ({
    favorsForFilter: state.favors?.favorsForFilter?.favors,
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const { close } = useModal();
  const loading = FindLoad("GET_FAVORS_FOR_FILTER");
  const activeItem = info?.activeItem;

  useEffect(() => {
    if (activeItem) {
      setShowActiveItem(activeItem);
    }
  }, [activeItem]);

  useEffect(() => {
    const params = {
      ...info,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;

    dispatch(favorsActions.getFavorsForFilter(params));
  }, [
    dispatch,
    info?.skip,
    info?.limit,
    info?.orderType,
    info?.sortType,
    info?.name,
  ]);

  return (
    <div className="see-items-modal-wrapper">
      <div className="modal2-header">
        <div className="modal2-header_title">Items</div>
        <Svg.Close onClick={close} />
      </div>
      <div className="modal2-body-footer-wrapper">
        <div className="modal2-body-wrapper">
          <div className="see-items-search_input-filter">
            <SearchInput
              placeholder={"Search by name or cost..."}
              searchKey={"name"}
              className="see-items-search_input"
            />

            <SortBy items={sortItems} />
          </div>
          <div className="see-items-box">
            {loading ? (
              <PageLoader
                height={"100%"}
                wrapperHeight={"100%"}
                wrapperWidth={"100%"}
              />
            ) : (
              !!favorsForFilter?.length &&
              favorsForFilter?.map((item, index) => (
                <FavorSmallCard
                  className="see-items_single"
                  item={item}
                  key={index}
                  activeItem={showActiveItem}
                  onClick={(item) => setShowActiveItem(item)}
                />
              ))
            )}
          </div>
        </div>

        <div className="modal2-footer-wrapper">
          <button
            type="button"
            className="modal2-footer-cancel-button"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
          <ButtonMain
            type="button"
            text="Send"
            onClick={() => {
              handleItemInList(showActiveItem, location);
              close();
            }}
          />
        </div>
      </div>
    </div>
  );
};
