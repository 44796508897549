import { CircularProgress } from "@mui/material";
import React from "react";

export const PageLoader = ({ height, wrapperHeight, wrapperWidth }) => {
  return (
    <div
      className="page-loader"
      style={{ height: wrapperHeight || "100vh", width: wrapperWidth }}
    >
      <div className="page-loader-box" style={height ? { height: height } : {}}>
        <CircularProgress
          style={{
            width: "100px",
            height: "100px",
            color: "#000",
          }}
        />
      </div>
    </div>
  );
};
