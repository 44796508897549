import {
  createFavor,
  editFavor,
  getFavors,
  deleteFavor,
  getFavorsForFilter,
} from "./favors.action";

export { favorsReducer } from "./favors.reducer";
export { watchFavors } from "./favors.saga";

export const favorsActions = {
  /** Favors Actions */
  createFavor,
  editFavor,
  getFavors,
  getFavorsForFilter,
  deleteFavor,
};
