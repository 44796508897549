import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import {
  Account,
  Profile,
  settingsTabList,
  DELETE_ACCOUNT,
  BankAccount,
} from "./fragments";
import { ProfileInfo, useModal } from "utils";
import { authActions } from "store";
import {
  DeleteButton,
  DeleteModal,
  SimpleTabs,
  TitleAndAction,
} from "components";

export const Settings = () => {
  const location = useLocation();
  const info = location?.state;
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const { myProfile, ifAdmin } = ProfileInfo();

  return (
    <div className="setting-page">
      <TitleAndAction title="Settings" />

      <div className="tab-and-search">
        <SimpleTabs
          list={settingsTabList(ifAdmin)}
          defaultTab={settingsTabList(ifAdmin)[0]?.value}
        />
      </div>

      {(!info?.tab || info?.tab === settingsTabList(ifAdmin)[0]?.value) && (
        <Profile />
      )}
      {info?.tab === settingsTabList(ifAdmin)[1]?.value && <Account />}
      {info?.tab === settingsTabList(ifAdmin)[2]?.value && !ifAdmin && (
        <BankAccount />
      )}
      {info?.tab !== settingsTabList(ifAdmin)[2]?.value && !ifAdmin && (
        <div className="delete-account-wrapper">
          <DeleteButton
            label={"Delete User"}
            handleClick={() => {
              openModal(
                <DeleteModal
                  title={"Are you sure you want to delete user?"}
                  description={
                    "Deleting the user will remove it from the system."
                  }
                  handleDelete={() =>
                    dispatch(authActions.deleteAccount(myProfile?.id))
                  }
                  actionType={DELETE_ACCOUNT}
                />,
                {
                  padding: "noPaddings",
                  noShowCloseBrn: true,
                }
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
