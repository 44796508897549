import { call, put, takeLatest } from "redux-saga/effects";
import { favorsService } from "./favors.service";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { Notify } from "utils";
import {
  CREATE_FAVOR,
  DELETE_FAVOR,
  EDIT_FAVOR,
  GET_FAVORS,
  GET_FAVORS_FOR_FILTER,
  GET_FAVORS_FOR_FILTER_SUCCESS,
  GET_FAVORS_SUCCESS,
} from "./favors.types";

function* createFavor({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(favorsService.createFavorService, payload?.body);
    Notify("Favor created successfully");

    yield put({
      type: GET_FAVORS,
      payload: { params: payload.params, load: payload?.load },
    });

    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editFavor({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(
      favorsService.editFavorService,
      payload?.params?.id,
      payload?.body
    );
    Notify("Favor edited successfully");
    yield put({
      type: GET_FAVORS,
      payload: { params: payload.params, load: payload?.load },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteFavor({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(favorsService.deleteFavorsService, payload?.params?.id);
    Notify("Favor deleted successfully");
    yield put({
      type: GET_FAVORS,
      payload: { params: payload.params, load: payload?.load },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getFavors({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(favorsService.getFavorsService, payload?.params);
    yield put({
      type: GET_FAVORS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* getFavorsForFilter({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(favorsService.getFavorsService, payload?.params);
    yield put({
      type: GET_FAVORS_FOR_FILTER_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(
      httpRequestsOnErrorsActions.appendError(type, err?.data?.message)
    );
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

export const watchFavors = function* () {
  yield takeLatest(CREATE_FAVOR, createFavor);
  yield takeLatest(EDIT_FAVOR, editFavor);
  yield takeLatest(DELETE_FAVOR, deleteFavor);
  yield takeLatest(GET_FAVORS, getFavors);
  yield takeLatest(GET_FAVORS_FOR_FILTER, getFavorsForFilter);
};
