import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Svg } from "assets";
import { Loader } from "components";

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];

export const UploadSingle = ({
  classNameWrapper,
  UploadImage,
  loaderUpload,
  setImgSrc,
  imageSrc,
  backError,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    if (e && e.size > 2097152) {
      setError(true);
    } else {
      setError(false);

      const image = e && URL.createObjectURL(e);
      setImgSrc({
        file: e,
        url: image,
      });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setImgSrc(null);
  };

  return (
    <div
      className={`upload-section-box ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <div
        className={`file-uploader ${backError ? "file-uploader-error" : ""}`}
      >
        <FileUploader
          multiple={false}
          types={fileTypes}
          handleChange={handleChange}
          name="file"
        >
          <div className="upload-info-box">
            {loaderUpload ? (
              <Loader
                type="ThreeDots"
                color="#FFFFFF"
                height={16}
                width={16}
                style={{ margin: "10px", padding: "0" }}
              />
            ) : (
              <div
                className="single_uploading_img_wrapper"
                onClick={(e) => e.stopPropagation()}
              >
                {imageSrc?.url ? (
                  <div className="single_uploading_img-box">
                    <div className="uploading_img-wrapper">
                      <img src={imageSrc?.url} alt="upload" />
                    </div>

                    <div
                      className="single_uploading_delete-icon"
                      onClick={handleDelete}
                    >
                      <Svg.Trash />
                    </div>
                  </div>
                ) : (
                  UploadImage || <Svg.ImageUpload />
                )}
              </div>
            )}

            <p className="drag-drop-text">
              Drag & Drop or
              <div>
                <input style={{ display: "none" }} />
                <i className="fa fa-cloud-upload" />
                <span>Browse</span>
              </div>
              Images
            </p>

            {error ? (
              <p>Max size must be 2 MB</p>
            ) : (
              <div className="information-box">
                <p>
                  Supported formats: <b>pdf, jpeg, png</b>
                </p>
                <p>Max size for each image 2 MB</p>
              </div>
            )}
          </div>
        </FileUploader>
      </div>
    </div>
  );
};
