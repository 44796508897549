import {
  CREATE_FAVOR,
  DELETE_FAVOR,
  EDIT_FAVOR,
  GET_FAVORS,
  GET_FAVORS_FOR_FILTER,
} from "./favors.types";

export const createFavor = (params, body) => {
  return {
    type: CREATE_FAVOR,
    payload: { params, body },
  };
};

export const editFavor = (params, body, load) => {
  return {
    type: EDIT_FAVOR,
    payload: { params, body, load },
  };
};

export const deleteFavor = (params, load) => {
  return {
    type: DELETE_FAVOR,
    payload: { params, load },
  };
};

export const getFavors = (params) => {
  return {
    type: GET_FAVORS,
    payload: { params },
  };
};
export const getFavorsForFilter = (params) => {
  return {
    type: GET_FAVORS_FOR_FILTER,
    payload: { params },
  };
};
