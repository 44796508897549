import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Svg } from "assets";
import { ButtonMain } from "components";
import { httpRequestsOnSuccessActions } from "store";
import { FindSuccess, useModal } from "utils";

export const DeleteModal = ({
  actionType,
  handleDelete,
  title,
  description,
  afterSuccess,
  customIcon,
  buttonMainStyles,
}) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const success = FindSuccess(actionType);

  useEffect(() => {
    if (success) {
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      if (afterSuccess) {
        afterSuccess();
      }
    }
  }, [success]);

  return (
    <div className="delete-modal">
      {customIcon ? customIcon : <Svg.DeleteCharity />}
      <div>
        <p className="delete-modal-title">{title}</p>
        <p className="delete-modal-desc">{description}</p>
      </div>
      <div
        className="flex-end"
        style={{ gap: 8, marginTop: 16, paddingTop: 8 }}
      >
        <button className="delete-modal-no-btn" onClick={close}>
          No
        </button>
        <ButtonMain
          buttonMainStyles={buttonMainStyles}
          actionTye={actionType}
          text="Yes"
          type="button"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};
