import axios from "axios";

export const favorsService = {
  createFavorService: (body) => axios.post("/favor", body, { auth: true }),

  getFavorsService: (params) => axios.get("/favor", { params: params }),

  deleteFavorsService: (id) => axios.delete(`/favor/${id}`, { auth: true }),

  editFavorService: (id, body) =>
    axios.patch(`/favor/${id}`, body, { auth: true }),
};
