import {
  GET_FAVORS_FOR_FILTER_SUCCESS,
  GET_FAVORS_SUCCESS,
} from "./favors.types";

const initialState = {
  favors: null,
  favorsForFilter: null,
};

export const favorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAVORS_SUCCESS:
      return {
        ...state,
        favors: action.payload,
      };

    case GET_FAVORS_FOR_FILTER_SUCCESS:
      return {
        ...state,
        favorsForFilter: action.payload,
      };

    default:
      return state;
  }
};
