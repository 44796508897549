import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routers } from "./root";
import { Layout, Footer, ResetPassword } from "fragments";
import { useModal } from "./utils";

const App = () => {
  const location = useLocation();

  const checkFooterToShow =
    location?.pathname !== "/create-fundraiser" &&
    location?.pathname !== "/in-celebration" &&
    location?.pathname !== "/in-memory-of" &&
    location?.pathname !== "/favor-items" &&
    !location?.pathname.startsWith("/connect-bank-account");

  const { openModal } = useModal();
  const query = new URLSearchParams(location.search);
  const [resetToken, setResetToken] = useState("");
  const resetTokenQuery = query.get("resetToken");
  const navigate = useNavigate();

  const contentColor = "#F4F4F4";

  useEffect(() => {
    if (resetToken) {
      openModal(<ResetPassword token={resetToken} />);
    }
  }, [resetToken]);

  useEffect(() => {
    if (resetTokenQuery) {
      setResetToken(resetTokenQuery);
      navigate("/");
    }
  }, [resetTokenQuery]);

  return (
    <div>
      <div className="app-box" style={{ background: contentColor }}>
        <Layout />
        <Routers />

        {checkFooterToShow && <Footer />}
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
