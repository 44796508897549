import React from "react";
import { PriceRow } from "components";

export const FavorSmallCard = ({ item, className, onClick, activeItem }) => {
  return (
    <div
      className={`favor-small-card-wrapper
       ${activeItem?.id === item?.id ? "favor-small-card-active" : ""}
        ${className ? className : ""}`}
      onClick={() => onClick && onClick(item)}
    >
      <div className="favor-small-card-box">
        <div className="favor-small-card-image">
          <img src={item?.image?.url} alt="favor" />
        </div>
        <div className="favor-small-card-price">
          <PriceRow info={item.price} />
        </div>
      </div>

      <p className="favor-small-card-name">{item.name}</p>
    </div>
  );
};
