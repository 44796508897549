import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { Svg } from "assets";
import { SaveParams } from "utils";

export const SearchInput = ({ placeholder, searchKey, className }) => {
  const [text, setText] = useState("");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;

  useEffect(() => {
    if (info) {
      setText(info[searchKey] || "");
    }
  }, [info]);

  const debounced = useDebouncedCallback(
    (value) => {
      const params = {
        ...info,
      };
      params.skip = 0;
      params.page = 1;

      if (value) {
        params[searchKey] = value;
      } else {
        delete params[searchKey];
      }
      SaveParams(location?.pathname, navigator, params);
    },
    500,
    { maxWait: 2000 }
  );

  const handleChange = (e) => {
    debounced(e.target.value);
    setText(e.target.value);
  };

  return (
    <div className={`search-box ${className ? className : ""}`}>
      <Svg.Search />
      <input
        type="text"
        name={searchKey}
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
      />
    </div>
  );
};
