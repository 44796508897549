export const sortItems = [
  { label: "A to Z", value: "ASC", sortType: "name", orderType: "ASC" },
  {
    label: "Highest to Lowest",
    value: "lowest",
    sortType: "price",
    orderType: "DESC",
  },
  {
    label: "Lowest to Highest",
    value: "Highest",
    sortType: "price",
    orderType: "ASC",
  },
];
