/** Combined Sagas */

import { fork } from "redux-saga/effects";
import { watchAuth } from "../auth";
import { watchFundraiser } from "../fundraiser";
import { watchUser } from "../user";
import { watchCharity } from "../charities";
import { watchCharityRequest } from "../charityRequest";
import { watchDonation } from "../donations";
import { watchBankAccount } from "../bankAccount";
import { watchTransfer } from "../transfers";
import { watchFavors } from "../favors";

export const appSaga = function* startForman() {
  yield fork(watchAuth);
  yield fork(watchFundraiser);
  yield fork(watchUser);
  yield fork(watchCharity);
  yield fork(watchCharityRequest);
  yield fork(watchDonation);
  yield fork(watchBankAccount);
  yield fork(watchTransfer);
  yield fork(watchFavors);
};
