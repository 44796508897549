import React, { useEffect } from "react";
import {
  PageLoader,
  PaginationSimple,
  PriceConvertor,
  SearchInput,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fundraiserActions } from "store";
import { NotYet } from "../../components/table/notYet";
import { JoinMovementCard } from "../home/fragments";
import { fundraiserStatuses } from "../fundraisers";
import { ACTION_TYPES } from "./fragments/constants";
import { FindLoad } from "utils";
import { Images } from "assets";

export const FindEvent = () => {
  const { fundraisersSearching } = useSelector((state) => ({
    fundraisersSearching: state.fundraiser.fundraisersSearching,
  }));
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPES);
  const location = useLocation();
  const info = location?.state;
  const navigate = useNavigate();

  useEffect(() => {
    const params = {
      limit: 10,
    };

    if (info?.keyword) {
      params.keyword = info.keyword;
    }
    if (info?.skip) {
      params.skip = info.skip;
    }

    dispatch(fundraiserActions.getFundraisersSearching(params));
  }, [dispatch, info]);

  return (
    <div className="find-event-page">
      <div className="container">
        <div className="find-event-box">
          <div className="find-event-title_search">
            <h2 className="find-event-title">Find an Event</h2>

            <p className="find-event-desc">
              Search for an events with or event code here
            </p>

            <SearchInput
              placeholder={"Search..."}
              searchKey={"keyword"}
              className="find-event-search"
            />
          </div>

          <div className="find-event-cards">
            {loader ? (
              <div style={{ height: "100%", width: "100%" }}>
                <PageLoader height={"60vh"} />
              </div>
            ) : (
              <div>
                {fundraisersSearching?.fundraisers?.length ? (
                  <div>
                    <div className="find-event-cards-wrapper">
                      {fundraisersSearching?.fundraisers.map((item, index) => (
                        <JoinMovementCard
                          stickerLive={
                            item?.type === "MEMORY"
                              ? "In Memory of"
                              : "In Celebration"
                          }
                          key={index}
                          classNameWrapper="find-event-card"
                          item={{
                            ...item,
                            img: item?.images?.length
                              ? item?.images[
                                  item?.mainImage ? item?.mainImage : 0
                                ]?.url
                              : Images.needCardFrameWhite,
                            title: item?.name,
                            description: "",
                            price: PriceConvertor({ price: item?.balance }),
                            percentage: "42%",
                          }}
                          allowButtons={true}
                          showFirstButton={
                            item?.status === fundraiserStatuses.LIVE
                          }
                          onClickFirstButton={() =>
                            navigate(`/donate/${item?.id}`)
                          }
                          onClickSecondButton={() =>
                            navigate(`/event/${item?.id}`)
                          }
                        />
                      ))}
                    </div>

                    {fundraisersSearching?.count > 0 && (
                      <PaginationSimple
                        count={fundraisersSearching?.count}
                        limitCountNumber={10}
                      />
                    )}
                  </div>
                ) : (
                  <NotYet height={"60vh"} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
