import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BackBtn, ButtonMain, PageLoader, SearchInput } from "components";
import { FavorSmallCard, SortBy, sortItems } from "fragments";
import { FindLoad, SaveParams } from "utils";
import { favorsActions } from "store";

export const FavorItems = () => {
  const { favorsForFilter } = useSelector((state) => ({
    favorsForFilter: state.favors?.favorsForFilter?.favors,
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const info = location?.state;
  const loading = FindLoad("GET_FAVORS_FOR_FILTER");

  useEffect(() => {}, []);

  useEffect(() => {
    const params = {
      ...info,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;

    dispatch(favorsActions.getFavorsForFilter(params));
  }, [
    dispatch,
    info?.skip,
    info?.limit,
    info?.orderType,
    info?.sortType,
    info?.name,
  ]);

  const handleItemInList = (item, clickTo, location) => {
    if (clickTo === "modalItem") {
      SaveParams(location?.pathname, navigate, {
        ...info,
        activeItem: item,
      });
    } else {
      navigate(`/donate/${info?.id}`, {
        state: { ...info, activeItem: location?.state?.activeItem },
      });
    }
  };

  return (
    <div className="favor-items-page">
      <div className="container">
        <div className="favor-items-box">
          <div className="favor-items-page-back-btn-box">
            <BackBtn
              handleClick={() =>
                navigate(`/donate/${info?.id}`, {
                  state: { ...info },
                })
              }
            />
          </div>

          <div>
            <div className="favor-items-page-search_input-filter">
              <SearchInput
                placeholder={"Search by name or cost..."}
                searchKey={"name"}
                className="see-items-search_input"
              />

              <SortBy
                items={sortItems}
                classNameSortByButton="favor-items-sort-by-button"
              />
            </div>

            <div className="favor-items-page_items-box">
              {loading ? (
                <PageLoader
                  height={"100%"}
                  wrapperHeight={"100%"}
                  wrapperWidth={"100%"}
                />
              ) : (
                !!favorsForFilter?.length &&
                favorsForFilter?.map((item, index) => (
                  <FavorSmallCard
                    className="favor-items-see-items_single"
                    item={item}
                    key={index}
                    onClick={(item) =>
                      handleItemInList(item, "modalItem", location)
                    }
                    activeItem={info?.activeItem}
                  />
                ))
              )}
            </div>
          </div>

          <div className="favor-items-page-footer">
            <button
              type="button"
              className="modal2-footer-cancel-button"
              onClick={() => navigate(`/donate/${info?.id}`)}
            >
              Cancel
            </button>
            <ButtonMain
              type="button"
              text="Send"
              onClick={() => handleItemInList({}, "", location)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
