import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Radio,
} from "@mui/material";
import { SaveParams } from "../../utils";
import { useLocation, useNavigate } from "react-router";

export const AccordionRadio = ({ list }) => {
  const [expanded, setExpanded] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const info = location?.state;

  const handleChange = (index) => {
    if (expanded !== index) {
      setExpanded(index);
      SaveParams(location?.pathname, navigate, {
        ...info,
        activeItem: null,
      });
    }
  };

  return (
    list?.length > 0 && (
      <div className="accordion-radio-wrapper">
        {list.map((item, index) => (
          <Accordion
            className="accordion-radio-box"
            key={index}
            expanded={expanded === index + 1}
          >
            <AccordionSummary
              onClick={() => handleChange(index + 1)}
              className="accordion-radio-summary-wrapper"
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="accordion-radio-summary-box">
                <p className="accordion-radio-title">{item?.title}</p>

                <Radio
                  checked={expanded === index + 1}
                  onChange={() => handleChange(index + 1)}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-radio-details-wrapper">
              {item?.accordionDetails}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    )
  );
};
