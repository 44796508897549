import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  ButtonMain,
  InputMain,
  NumericFormatCustom,
  SelectMain,
  UploadSingle,
} from "components";
import { FindSuccess, useModal } from "utils";
import { favorsActions, httpRequestsOnSuccessActions } from "store";
import { Svg } from "assets";
import { types } from "./constants";
import axios from "axios";

export const CreateFavor = ({ item }) => {
  const ACTION_TYPES = item ? "EDIT_FAVOR" : "CREATE_FAVOR";
  const [imageSrc, setImgSrc] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { handleSubmit, control, reset } = useForm();
  const dispatch = useDispatch();
  const success = FindSuccess(ACTION_TYPES);
  const location = useLocation();
  const { close } = useModal();
  const info = location?.state;

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        type: types.find((i) => i.value === item?.type),
      });

      setImgSrc({ url: item?.image?.url });
    }
  }, [item]);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPES));
      close();
    }
  }, [success]);

  const handleCreate = async (data) => {
    const params = {
      ...info,
      limit: 10,
    };

    const sendDate = {
      ...data,
      price: +data?.price,
      type: data?.type?.value,
    };

    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;

    if (imageSrc) {
      if (imageSrc?.file) {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("files", imageSrc?.file);
        await axios
          .post(`/files/upload?includeThumbnail=true`, formData, { auth: true })
          .then((res) => {
            sendDate.image = res?.data;
            if (item) {
              dispatch(
                favorsActions.editFavor({ ...params, id: item?.id }, sendDate)
              );
            } else {
              dispatch(favorsActions.createFavor(params, sendDate));
            }
          })
          .catch(() => {
            setUploadError(true);
          })
          .finally(() => {
            setUploadLoading(false);
          });
      }

      if (item && !imageSrc?.file) {
        dispatch(
          favorsActions.editFavor({ ...params, id: item?.id }, sendDate)
        );
      }
    } else {
      setUploadError(true);
    }
  };

  return (
    <div className="create-favor-modal-wrapper">
      <div className="modal2-header">
        <p className="modal2-header_title">Create Items</p>
        <Svg.Close onClick={close} />
      </div>

      <form
        className="modal2-body-footer-wrapper"
        onSubmit={handleSubmit(handleCreate)}
      >
        <div className="modal2-body-wrapper">
          <InputMain
            className="celebration-input"
            name="name"
            control={control}
            placeholder="Enter Item Name"
            labelHeader="Item Name"
            rules={{
              required: "Title is required",
              validate: {
                trimmed: (value) =>
                  value.trim() !== "" || "Name cannot be empty or just spaces",
              },
            }}
          />

          <SelectMain
            list={types}
            renderLabel={(option) => option?.label}
            defaultValue={[]}
            name="type"
            type="text"
            control={control}
            placeholder="Select Type"
            labelHeader="Choose Type"
            rules={{
              required: "Title is required",
            }}
          />

          <NumericFormatCustom
            placeholder="$0.00"
            name="price"
            prefix={"$"}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            control={control}
            rules={{
              required: "Title is required",
            }}
          />

          <UploadSingle
            UploadImage={<Svg.UploadImageCelebration />}
            imageSrc={imageSrc}
            setImgSrc={setImgSrc}
            backError={uploadError}
          />
        </div>

        <div className="modal2-footer-wrapper">
          <button
            type="button"
            className="modal2-footer-cancel-button"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
          <ButtonMain
            type="submit"
            text="Save"
            load={uploadLoading}
            actionTye={ACTION_TYPES}
          />
        </div>
      </form>
    </div>
  );
};
