import { useState } from "react";
import axios from "axios";

export const DownloadFile = () => {
  const [loadDownload, setLoadDownload] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const handleDownload = async (file, id) => {
    if (id) {
      setCurrentId(id);
    }
    const fileName = file?.originalName ? file?.originalName : "";
    setLoadDownload(true);
    try {
      const response = await axios({
        url: `/files/download`,
        method: "POST",
        responseType: "blob",
        data: file,
      });
      setLoadDownload(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e) {
      setLoadDownload(false);
    } finally {
      setCurrentId(null);
    }
  };

  return {
    loadDownload,
    handleDownload,
    currentId,
  };
};
