import { combineReducers } from "redux";
import { httpRequestsOnLoadReducer } from "../http_requests_on_load";
import { httpRequestsOnSuccessReducer } from "../http_requests_on_success";
import { httpRequestsOnErrorsReducer } from "../http_requests_on_errors";
import { authReducer } from "../auth";
import { fundraiserReducer } from "../fundraiser";
import { userReducer } from "../user";
import { charityReducer } from "../charities";
import { donationReducer } from "../donations";
import { bankAccountReducer } from "../bankAccount";
import { charityRequestReducer } from "../charityRequest";
import { transferReducer } from "../transfers";
import { favorsReducer } from "../favors";

export const appReducer = combineReducers({
  auth: authReducer,
  fundraiser: fundraiserReducer,
  user: userReducer,
  charity: charityReducer,
  charityRequest: charityRequestReducer,
  donation: donationReducer,
  bankAccount: bankAccountReducer,
  transfer: transferReducer,
  favors: favorsReducer,

  httpOnLoad: httpRequestsOnLoadReducer,
  httpOnSuccess: httpRequestsOnSuccessReducer,
  httpOnError: httpRequestsOnErrorsReducer,
});
