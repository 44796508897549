import * as React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Svg } from "assets";

export const SelectMain = ({
  classNameWrapper,
  labelHeader,
  control,
  name,
  rules,
  placeholder,
  list,
  renderLabel,
  disabled,
}) => {
  return (
    <div
      className={`accordion-wrapper ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({
          field,
          field: { onChange, value },
          formState: { errors },
        }) => {
          return (
            <>
              {labelHeader && (
                <p
                  className={`input-main-label-header ${
                    errors[field?.name]?.message ? "errorLabel" : ""
                  }`}
                >
                  {labelHeader}
                  {rules?.required && (
                    <span className="input-main-label-header-required">*</span>
                  )}
                </p>
              )}
              <Autocomplete
                {...field}
                disabled={disabled}
                id="checkboxes-tags-demo"
                error={!!errors[field?.name]?.message}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors[field?.name]?.message
                      ? "#D03325"
                      : "#555768",
                  },
                }}
                popupIcon={<Svg.ArrowDown />}
                options={list}
                value={value || { value: "", label: "" }}
                getOptionLabel={(option) => renderLabel(option)}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                onChange={(event, item) => {
                  onChange(item);
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li
                      {...props}
                      key={option?.value}
                      className={`main-select-option ${
                        selected ? "main-select-option-selected" : ""
                      }`}
                    >
                      {renderLabel(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={placeholder} />
                )}
              />
              <p className="error-message">{errors[field?.name]?.message}</p>
            </>
          );
        }}
      />
    </div>
  );
};
