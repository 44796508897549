import * as React from "react";
import Popper from "@mui/material/Popper";
import { Svg } from "assets";
import { Box } from "@mui/material";
import { DownloadFile, Notify, useModal, useWindowDimensions } from "utils";
import { useState } from "react";
import { ButtonMain } from "./buttonMain";

export function ShareBtn({
  background,
  fill,
  buttonText,
  textColor,
  classNameButton,
  width,
  border,
  fundraiserById,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { openModal, close } = useModal();
  const { width: screenWidth } = useWindowDimensions();
  const { loadDownload, handleDownload } = DownloadFile();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    if (screenWidth < 768) {
      openModal(
        <div className="share-select-paper-box">
          <Box>
            <div className="share-popper-box">
              <div className="share-title-close-button">
                <p className="share-title">Share</p>

                <button className="share-close-button" onClick={() => close()}>
                  <Svg.Close />
                </button>
              </div>
              {/*<p className="share-title">Share</p>*/}
              {/*<div className="share-social-wrapper">*/}
              {/*  <div className="share-social-box">*/}
              {/*    <div className="social-box">*/}
              {/*      <Svg.TwitterShare />*/}
              {/*      <p>Twitter</p>*/}
              {/*    </div>*/}
              {/*    <div className="social-box">*/}
              {/*      <Svg.LinkedinShare />*/}
              {/*      <p>Linkedin</p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="share-social-box">*/}
              {/*    <div className="social-box">*/}
              {/*      <Svg.FacebookShare />*/}
              {/*      <p>Facebook</p>*/}
              {/*    </div>*/}
              {/*    <div className="social-box">*/}
              {/*      <Svg.InstagramShare />*/}
              {/*      <p>Instagram</p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="share-and-copy-link">
                <div className="share-link">
                  <input value={currentLink} type="text" readOnly={true} />
                </div>
                <button onClick={copyLink} className="copy-link-btn">
                  <Svg.CopyLink />
                </button>
              </div>

              {fundraiserById?.qrCode && (
                <div className="qr-code_down_button">
                  <div className="qr-code-imageBlock">
                    <img src={fundraiserById?.qrCode?.url} alt="qr" />
                  </div>

                  <ButtonMain
                    classNameWrapper={`download-qr-code-button`}
                    startIcon={<Svg.DownQR />}
                    text="Download QR Code"
                    type="button"
                    load={loadDownload}
                    onClick={() => handleDownload(fundraiserById?.qrCode)}
                  />
                </div>
              )}
            </div>
          </Box>
        </div>,
        {
          padding: "noPaddings",
          noShowCloseBrn: true,
        }
      );
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const currentLink = window.location.href;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(currentLink);
      Notify("Link copied");
    } catch (err) {
      Notify("Error while copying", "error");
    }
  };

  return (
    <div className="share-popper-wrapper" style={{ width }}>
      <button
        style={{ backgroundColor: background, border }}
        aria-describedby={id}
        onClick={handleClick}
        className={`share-btn ${classNameButton ? classNameButton : ""}`}
      >
        {!buttonText && <Svg.Share fill={fill} />}
        {buttonText && <p style={{ color: textColor }}>{buttonText}</p>}
      </button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={"share-select-paper-box"}
        style={{ position: "absolute", zIndex: 9 }}
      >
        <Box>
          <div className="share-popper-box">
            <div className="share-title-close-button">
              <p className="share-title">Share</p>
            </div>
            {/*<p className="share-title">Share</p>*/}
            {/*<div className="share-social-wrapper">*/}
            {/*  <div className="share-social-box">*/}
            {/*    <div className="social-box">*/}
            {/*      <Svg.TwitterShare />*/}
            {/*      <p>Twitter</p>*/}
            {/*    </div>*/}
            {/*    <div className="social-box">*/}
            {/*      <Svg.LinkedinShare />*/}
            {/*      <p>Linkedin</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="share-social-box">*/}
            {/*    <div className="social-box">*/}
            {/*      <Svg.FacebookShare />*/}
            {/*      <p>Facebook</p>*/}
            {/*    </div>*/}
            {/*    <div className="social-box">*/}
            {/*      <Svg.InstagramShare />*/}
            {/*      <p>Instagram</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="share-and-copy-link">
              <div className="share-link">
                <input value={currentLink} type="text" readOnly={true} />
              </div>
              <button onClick={copyLink} className="copy-link-btn">
                <Svg.CopyLink />
              </button>
            </div>

            {fundraiserById?.qrCode && (
              <div className="qr-code_down_button">
                <div className="qr-code-imageBlock">
                  <img src={fundraiserById?.qrCode?.url} alt="qr" />
                </div>

                <ButtonMain
                  classNameWrapper={`download-qr-code-button`}
                  startIcon={<Svg.DownQR />}
                  text="Download QR Code"
                  type="button"
                  load={loadDownload}
                  onClick={() => handleDownload(fundraiserById?.qrCode)}
                />
              </div>
            )}
          </div>
        </Box>
      </Popper>

      {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
    </div>
  );
}
