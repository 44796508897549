import {
  DateRow,
  ImageAndName,
  MobileCardBody,
  MobileCardHead,
  PriceRow,
  TextRow,
} from "components";
import { Svg, Images } from "assets";

export const ACTION_TYPES = {
  GET_FAVORS: "GET_FAVORS",
  DELETE_FAVOR: "DELETE_FAVOR",
};

export const favorsHead = (width) => {
  return width > 1279
    ? [
        { title: "Item Name", sortType: "name", width: "25%" },
        { title: "Creation Date", sortType: "createdAt", width: "25%" },
        { title: "Type", width: "25%" },
        { title: "Cost", width: "25%" },
        { title: "Action", width: "25%" },
      ]
    : [
        { title: "Item Name", sortType: "name", width: "32%" },
        { title: "Type", width: "34%" },
        { title: "Cost", width: "38%" },
      ];
};

const renderTypes = {
  CELEBRATION: "In Celebration",
  MEMORY: "In Memory of",
};

export const types = [
  {
    value: "CELEBRATION",
    label: "In Celebration",
  },
  {
    value: "MEMORY",
    label: "In Memory of",
  },
];

export const favorsBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={item?.image?.url ? item?.image?.url : null}
              noSrc={<img src={Images.noFavorImg} alt="no favor" />}
            />
          ),
          notClickable: true,
          width: "25%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          notClickable: true,
        },
        {
          rowText: (item) => <TextRow text={renderTypes[item?.type]} />,
          notClickable: true,
          width: "25%",
        },
        {
          rowText: (item) => <PriceRow info={item.price} />,
          notClickable: true,
          width: "25%",
        },
        {
          button: (fn) => (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <button
                onClick={() => fn("delete")}
                className="delete-btn-box"
                style={{ padding: "6px 16px" }}
              >
                <Svg.RedTrashFill style={{ width: "24px", height: "24px" }} />
                <p>Delete</p>
              </button>

              <button
                onClick={() => fn("edit")}
                className="delete-btn-box"
                style={{ padding: "6px 16px" }}
              >
                <Svg.EditBlueSvg style={{ width: "24px", height: "24px" }} />
                <p style={{ color: "#004080" }}>Edit</p>
              </button>
            </div>
          ),
          width: "25%",
        },
      ]
    : [
        {
          rowText: (item) => (
            <ImageAndName
              text={item?.name}
              src={item?.image?.url ? item?.image?.url : null}
              noSrc={<img src={Images.noFavorImg} alt="no favor" />}
            />
          ),
          width: "33%",
          notClickable: true,
        },

        {
          rowText: (item) => <TextRow text={renderTypes[item?.type]} />,
          width: "33%",
          notClickable: true,
        },

        {
          rowText: (item) => <PriceRow info={item?.price} />,
          width: "33%",
          notClickable: true,
        },
      ];
};

// eslint-disable-next-line react/display-name
export const favorsAccordionDetails = (handleClick) => (item) => {
  return (
    <div className="favorsAccordionDetails-wrapper">
      <div className="favorsAccordionDetails-created-date">
        <span>Creation Date:</span> <DateRow date={item?.createdAt} />
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <button
          onClick={() => handleClick(item, "delete")}
          className="delete-btn-box"
          style={{ padding: "6px 16px" }}
        >
          <Svg.RedTrashFill style={{ width: "24px", height: "24px" }} />
          <p>Delete</p>
        </button>

        <button
          onClick={() => handleClick(item, "edit")}
          className="delete-btn-box"
          style={{ padding: "6px 16px" }}
        >
          <Svg.EditBlueSvg style={{ width: "24px", height: "24px" }} />
          <p style={{ color: "#004080" }}>Edit</p>
        </button>
      </div>
    </div>
  );
};

export const favorsMobileCard = (handleClick) => {
  return [
    {
      rowText: (item) => (
        <MobileCardHead
          src={item?.image?.url ? item?.image?.url : null}
          noSrc={<img src={Images.noFavorImg} alt="no favor" />}
          value={item?.name}
        />
      ),
    },
    {
      rowText: (item) => (
        <MobileCardBody
          title="Creation Date"
          value={<DateRow date={item?.createdAt} />}
        />
      ),
    },
    {
      rowText: (item) => (
        <MobileCardBody
          title="Type"
          value={<TextRow text={renderTypes[item?.type]} />}
        />
      ),
    },
    {
      rowText: (item) => (
        <MobileCardBody title="Cost" value={<PriceRow info={item?.price} />} />
      ),
    },

    {
      rowText: (item) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "4px",
            padding: "8px 16px",
            borderTop: "1px solid #F4F4F4",
          }}
        >
          <button
            onClick={() => handleClick(item, "delete")}
            className="delete-btn-box"
            style={{ padding: "6px 16px" }}
          >
            <Svg.RedTrashFill style={{ width: "24px", height: "24px" }} />
            <p>Delete</p>
          </button>

          <button
            onClick={() => handleClick(item, "edit")}
            className="delete-btn-box"
            style={{ padding: "6px 16px" }}
          >
            <Svg.EditBlueSvg style={{ width: "24px", height: "24px" }} />
            <p style={{ color: "#004080" }}>Edit</p>
          </button>
        </div>
      ),
    },
  ];
};
