import React, { useState } from "react";
import moment from "moment/moment";
import { PriceRow } from "components";
import { Images } from "assets";

export const ContributionCard = ({ item }) => {
  const [showMessages, setShowMessages] = useState(false);
  return (
    <div className="contribution-card-wrapper">
      <p className="contribution-card-name">{item?.donorName}</p>
      <div className="contribution-card-date-amount">
        <p className="contribution-card-date">
          {moment(item?.createdAt).format("MM/DD/YYYY")}
        </p>
        <p className="contribution-card-amount">
          <PriceRow info={item?.amount} />
        </p>
      </div>

      <div className="contribution-card-date-amount">
        <p className="contribution-card-date">Item</p>

        <div style={{ width: "40px", height: "40px" }}>
          <img
            src={item?.favor?.image?.url || Images.defaultItem}
            alt="favor"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      {item?.description && (
        <button
          className="contribution-card-button"
          onClick={() => setShowMessages((prevState) => !prevState)}
        >
          {!showMessages ? "See Message" : "Hide Message"}
        </button>
      )}

      {showMessages && item?.description && (
        <p className="contribution-card-messages">{item?.description}</p>
      )}
    </div>
  );
};
